import React from "react"

const Icons = () => (<>
  <link rel="icon" type="image/png" sizes="16x16" href="/static/icons/favicon-16-16.png?h=fbf95334" />
  <link rel="icon" type="image/png" sizes="24x24" href="/static/icons/favicon-24-24.png?h=cc53d6b9" />
  <link rel="icon" type="image/png" sizes="32x32" href="/static/icons/favicon-32-32.png?h=bbe890df" />
  <link rel="icon" type="image/png" sizes="48x48" href="/static/icons/favicon-48-48.png?h=016736ce" />
  <link rel="icon" type="image/png" sizes="64x64" href="/static/icons/favicon-64-64.png?h=4b0b43e3" />
  <link rel="icon" type="image/png" sizes="72x72" href="/static/icons/favicon-72-72.png?h=a0a4644b" />
  <link rel="icon" type="image/png" sizes="96x96" href="/static/icons/favicon-96-96.png?h=eba578a6" />
  <link rel="icon" type="image/png" sizes="128x128" href="/static/icons/favicon-128-128.png?h=b7b5ca8a" />
  <link rel="icon" type="image/png" sizes="48x48" href="/static/icons/android-chrome-48-48.png?h=016736ce" />
  <link rel="icon" type="image/png" sizes="256x256" href="/static/icons/favicon-256-256.png?h=0d5a0cb9" />
  <link rel="icon" type="image/png" sizes="36x36" href="/static/icons/android-chrome-36-36.png?h=f188c7cb" />
  <link rel="icon" type="image/png" sizes="72x72" href="/static/icons/android-chrome-72-72.png?h=a0a4644b" />
  <link rel="icon" type="image/png" sizes="96x96" href="/static/icons/android-chrome-96-96.png?h=eba578a6" />
  <link rel="icon" type="image/png" sizes="144x144" href="/static/icons/android-chrome-144-144.png?h=3d93f1c7" />
  <link rel="icon" type="image/png" sizes="192x192" href="/static/icons/android-chrome-192-192.png?h=2f9f46ec" />
  <link rel="icon" type="image/png" sizes="384x384" href="/static/icons/android-chrome-384-384.png?h=ac359ed7" />
  <link rel="icon" type="image/png" sizes="256x256" href="/static/icons/android-chrome-256-256.png?h=fcfecd1a" />
  <link rel="icon" type="image/png" sizes="512x512" href="/static/icons/android-chrome-512-512.png?h=16ec2b26" />
  <link rel="apple-touch-icon" sizes="57x57" href="/static/icons/apple-touch-icon-57-57.png?h=cc023530" />
  <link rel="apple-touch-icon" sizes="60x60" href="/static/icons/apple-touch-icon-60-60.png?h=b1eaed7f" />
  <link rel="apple-touch-icon" sizes="72x72" href="/static/icons/apple-touch-icon-72-72.png?h=a0a4644b" />
  <link rel="apple-touch-icon" sizes="76x76" href="/static/icons/apple-touch-icon-76-76.png?h=c78e2747" />
  <link rel="apple-touch-icon" sizes="114x114" href="/static/icons/apple-touch-icon-114-114.png?h=ad8bdb0e" />
  <link rel="apple-touch-icon" sizes="120x120" href="/static/icons/apple-touch-icon-120-120.png?h=a087a935" />
  <link rel="apple-touch-icon" sizes="144x144" href="/static/icons/apple-touch-icon-144-144.png?h=3d93f1c7" />
  <link rel="apple-touch-icon" sizes="152x152" href="/static/icons/apple-touch-icon-152-152.png?h=7dddaa11" />
  <link rel="apple-touch-icon" sizes="180x180" href="/static/icons/apple-touch-icon-180-180.png?h=c4e0d2b3" />
  <link rel="apple-touch-icon" sizes="167x167" href="/static/icons/apple-touch-icon-167-167.png?h=bdaec8c4" />
  <link rel="apple-touch-icon" sizes="180x180" href="/static/icons/apple-touch-icon-precomposed.png?h=c4e0d2b3" />
  <link rel="apple-touch-icon" sizes="1024x1024" href="/static/icons/apple-touch-icon.png?h=8e09f521" />
  <link rel="apple-touch-icon" media="(device-width: 320) and (device-height: 568) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="/static/icons/apple-launch-se-640-1136.png?h=133bf640" />
  <link rel="apple-touch-icon" media="(device-width: 375) and (device-height: 667) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="/static/icons/apple-launch-8-750-1334.png?h=120d02a4" />
  <link rel="apple-touch-icon" media="(device-width: 414) and (device-height: 896) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="/static/icons/apple-launch-11-828-1792.png?h=08455418" />
  <link rel="apple-touch-icon" media="(device-width: 375) and (device-height: 812) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="/static/icons/apple-launch-xs-1125-2436.png?h=cafcfb13" />
  <link rel="apple-touch-icon" media="(device-width: 390) and (device-height: 844) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="/static/icons/apple-launch-12-1170-2532.png?h=2864f54a" />
  <link rel="apple-touch-icon" media="(device-width: 414) and (device-height: 896) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="/static/icons/apple-launch-11-pro-max-1242-2688.png?h=9ef106e2" />
  <link rel="apple-touch-icon" media="(device-width: 414) and (device-height: 736) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="/static/icons/apple-launch-8-plus-1242-2208.png?h=bf517115" />
  <link rel="apple-touch-icon" media="(device-width: 414) and (device-height: 736) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="/static/icons/apple-launch-6-plus-1242-2208.png?h=bf517115" />
  <link rel="apple-touch-icon" media="(device-width: 428) and (device-height: 926) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="/static/icons/apple-launch-12-pro-max-1284-2778.png?h=47412b34" />
  <link rel="apple-touch-icon" media="(device-width: 376) and (device-height: 667) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3)" href="/static/icons/apple-launch-screen-default.png?h=a27df8c3" />
  <link rel="apple-touch-icon" href="/static/icons/apple-launch-screen-default.png?h=a27df8c3" />
  <link rel="apple-touch-icon" media="(device-width: 768) and (device-height: 1024) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="/static/icons/apple-launch-ipad-pro-s-1536-2048.png?h=954d5367" />
  <link rel="apple-touch-icon" media="(device-width: 810) and (device-height: 1080) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="/static/icons/apple-launch-ipad-pro-1620-2160.png?h=ee9b91eb" />
  <link rel="apple-touch-icon" media="(device-width: 834) and (device-height: 1194) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="/static/icons/apple-launch-ipad-pro-l-1668-2388.png?h=e17a5b30" />
  <link rel="apple-touch-icon" media="(device-width: 834) and (device-height: 1112) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="/static/icons/apple-launch-air-1668-2224.png?h=70dfa2c5" />
  <link rel="apple-touch-icon" media="(device-width: 1024) and (device-height: 1366) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2)" href="/static/icons/apple-launch-ipad-pro-xl-2048-2732.png?h=12a7347c" />
  <link rel="shortcut icon" href="/static/icons/favicon.ico?h=2eeb6cd1" />
</>);

export default Icons;